import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { ArticleService } from "../../services/article.service";
import { debounceTime, fromEvent } from "rxjs";
import { Article } from "../../services/article.interface";

@Component({
  selector: "app-article-list",
  templateUrl: "./article-list.component.html",
  styleUrls: ["./article-list.component.less"],
})
export class ArticleListComponent implements AfterViewInit {

  @ViewChild("filterInput", { static: true }) filterInput: | ElementRef<HTMLInputElement>;

  public list: Array<Article> = [];
  public keyword: string = "";
  public loading: boolean = false;
  public selectedArticle: Article | undefined;

  constructor(private readonly articleService: ArticleService) { }

  public setInputFieldFocus(input: ElementRef<HTMLInputElement> | undefined): void {
    setTimeout(() => {
      input?.nativeElement.focus();
    }, 100);
  }

  ngAfterViewInit(): void {

    // attach keyup event handler to search field
    fromEvent(this.filterInput.nativeElement, 'keyup').pipe(debounceTime(500)).subscribe({
      next: () => {
        this.filter(this.filterInput.nativeElement.value)
      }
    });

    // setup autofocus to search field
    this.setInputFieldFocus(this.filterInput);
  }

  public openDetails(sku: string) {

    // reset (toggle on UI)
    if (this.selectedArticle && sku === this.selectedArticle.sku) {
      this.selectedArticle = undefined;
      return;
    }

    // fetch details of article
    this.articleService.getArticle(sku).subscribe({
      next: (article: Article) => this.selectedArticle = article,
      error: (e) => { console.error(e) }
    })
  }

  public filter(keyword: string) {

    this.keyword = keyword.toLowerCase();

    if (!this.keyword || this.keyword.length < 3) {
      this.list = [];
      return;
    }

    this.loading = true;

    this.articleService.searchArticle(this.keyword).subscribe({
      next: (articles: Article[]) => {
        this.list = articles;
        this.loading = false;
      },
      error: (e) => { console.error(e); this.loading = false; }
    })

  }
}
