import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SessionService } from '../../../services/session.service';
import { ISession, ISessionProduct } from '../../../services/session.interface';
import { MessageService } from 'src/app/services/message/message.service';
import { Article, ArticleType, GoodCategory, GoodSubCategory, SelectableArticle } from 'src/app/modules/article/services/article.interface';
import { SessionUtils } from '../utils/session-utils';

enum Tabs {
  'HISTORY' = 'HISTORY',
  'WASHER' = 'WASHER',
  'TIRESERVICE' = 'TIRESERVICE',
  'CARSERVICE' = 'CARSERVICE',
  'PRODUCTS' = 'PRODUCTS'
}

@Component({
  selector: 'app-customer-history',
  templateUrl: './customer-history.component.html',
  styleUrls: ['./customer-history.component.less']
})
export class CustomerHistoryComponent extends SessionUtils implements OnChanges {

  @Input() plate: string;
  @Input() goodList: Article[] = [];
  @Output() goodSelected: EventEmitter<SelectableArticle> = new EventEmitter();

  public licensePlate: string;
  public loading: boolean = false;

  public filters: string[] = [];
  public availableFilters = GoodCategory;

  public goods: Array<Article> = [];
  public visibleGoods: Array<Article> = [];

  // Items with these subcategories are not allowed to recreate in a session
  public goodSubCategoriesBlackList = [GoodSubCategory.DISCOUNT];

  constructor(
    private readonly sessionService: SessionService, 
    private readonly messageService: MessageService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('plate' in changes) {
      this.licensePlate = changes['plate'].currentValue;
      if (!this.licensePlate) { this.goods = []; return; }
      this.getHistory();
    }
  }

  public addProductToCart(sku: string) {
    const good = this.goodList.find((article: Article) => article.sku === sku);
    if (good) {
      this.selectProduct(good);
    }
  }

  public setFilter(filter: string): void {

    // in case of product, execute filter setting twice to add / remove promotion products also into / from the filter list
    const resolveFilter = (filter: string) => filter === ArticleType.PRODUCT ? this.setFilter(GoodCategory.PROMOTION) : null;

    resolveFilter(filter);

    const found = this.filters.findIndex((savedFilter) => filter === savedFilter);

    if (found === -1) {
      this.filters.push(filter);
    } else {
      this.filters.splice(found, 1);
    }

    this.executeFiltering();
  }

  private executeFiltering(): void {

    if (this.filters.length === 0) {
      this.visibleGoods = this.goods;
      return;
    }

    this.visibleGoods = this.goods.filter((article: Article) => this.filters.includes(article.category));
  }

  public selectProduct(good: Article): void {
    const item = good as SelectableArticle;
    item.quantity = 1;
    this.goodSelected.emit(item);
  }

  private getHistory(): void {

    if (!this.plate || this.plate.length <= 3) {
      this.goods = [];
      this.loading = false;
      return;
    }

    this.loading = true;
    this.sessionService.searchSessionByLicenesPlate({ plate: this.licensePlate }).subscribe({
      next: (sessions: Array<ISession>) => {

        if (sessions.length === 0) {
          this.goods = [];
          this.loading = false;
          return;
        }

        // collect good ids from previous sessions
        const goodSkus = sessions
          .map((session: ISession) => session.article)
          .reduce((accum, current) => { return [...accum, ...current] }, [])
          .map((product: ISessionProduct) => product.sku);

        for (const sku of goodSkus) {

          const good = this.goodList.find((good: Article) => good.sku === sku);

          if (good && !this.goodSubCategoriesBlackList.includes(good.subCategory as GoodSubCategory)) {
            this.goods.push(good);
          }
        }

        this.loading = false;
        this.executeFiltering();
      },
      error: (e) => this.messageService.createMessage(e.error, 'error')
    })
  }

}
