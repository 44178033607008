import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  ArticleListComponent
} from "./components/article-list/article-list.component";
import { ArticleDetailsComponent } from "./components/article-details/article-details.component";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ArticleService } from "./services/article.service";

@NgModule({
  declarations: [
    ArticleListComponent,
    ArticleDetailsComponent
  ],
  providers: [ArticleService],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class ArticleModule { }
