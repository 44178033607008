<div class="elements">
    <div class="element">
        <div class="head" (click)="publish(availableGoodTypes.SERVICE,availableGoodCategories.WASH)">
            <span class="fa fa-search"></span>
            <span>Mosó</span>
        </div>
        <div class="body">
            <ul>
                <li *ngFor="let element of productList[availableGoodCategories.WASH]"><a
                        (click)="publishSelectedProduct(element)">{{ProductElements[element]}}</a></li>
            </ul>
        </div>
    </div>
    <div class="element">
        <div class="head" (click)="publish(availableGoodTypes.SERVICE,availableGoodCategories.INSTALL)">
            <span class="fa fa-search"></span>
            <span>Gumiszervíz</span>
        </div>
        <div class="body">
            <ul>
                <li *ngFor="let element of productList[availableGoodCategories.INSTALL]"><a
                        (click)="publishSelectedProduct(element)">{{ProductElements[element]}}</a></li>
            </ul>
        </div>
    </div>
    <div class="element">
        <div class="head" (click)="publish(availableGoodTypes.SERVICE,availableGoodCategories.REPAIR)">
            <span class="fa fa-search"></span>
            <span>Autószervíz</span>
        </div>
        <div class="body">
            <ul>
                <li *ngFor="let element of productList[availableGoodCategories.REPAIR]"><a
                        (click)="publishSelectedProduct(element)">{{ProductElements[element]}}</a></li>
            </ul>
        </div>
    </div>
    <div class="element">
        <div class="head" (click)="publish(availableGoodTypes.PRODUCT,availableGoodCategories.TIRE)">
            <span class="fa fa-search"></span>
            <span>Termékek</span>
        </div>
        <div class="body">
            ...
        </div>
    </div>
    <div class="element">
        <div class="body">
            <img class="logo" src="/assets/hell-promotion.jpeg" alt="">
            <ul>
                <li *ngFor="let element of productList[availableGoodCategories.PROMOTION]"><a
                    (click)="publishSelectedProduct(element)">{{ProductElements[element]}}</a></li>
            </ul>
        </div>
    </div>
</div>