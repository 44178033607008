<div class="container">
    <div class="row">
        <div class="column">
            <div>
                <input type="text" #filterInput placeholder="Keresés...">
            </div>
            <table>
                <thead>
                    <tr>
                        <th width="16"></th>
                        <th>ID</th>
                        <th>Cikkszám</th>
                        <th>Vonalkód</th>
                        <th>Megnevezés</th>
                        <th>Típus</th>
                        <th>Nettó egységár</th>
                        <th>Áfa (%)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="!list.length && keyword">
                        <td colspan="10">A keresésnek nincs eredménye...</td>
                    </tr>
                    <tr *ngIf="!list.length && !keyword">
                        <td colspan="10">Keressen cikkszámra / megnevezésre a fenti beviteli mező segítségével...</td>
                    </tr>
                    <ng-container *ngFor="let article of list">
                        <tr [ngClass]="{'selected-row': selectedArticle?.sku === article.sku}">
                            <td width="16">
                                <a style="cursor: pointer;display: block;text-align: center;"
                                    (click)="openDetails(article.sku)">
                                    <i *ngIf="!selectedArticle || selectedArticle.sku !== article.sku"
                                        class="fa fa-chevron-right"></i>
                                    <i *ngIf="selectedArticle && selectedArticle.sku === article.sku"
                                        class="fa fa-chevron-down"></i>
                                </a>
                            </td>
                            <td>{{article.id}}</td>
                            <td>{{article.sku}}</td>
                            <td>{{article.barcode}}</td>
                            <td>{{article.title}}</td>
                            <td>{{article.type}}</td>
                            <td>{{article.price | number}}</td>
                            <td>{{article.vat}} %</td>
                        </tr>
                        <tr *ngIf="selectedArticle && selectedArticle.sku == article.sku">
                            <td colspan="10">
                                <div style="padding-top:15px;">
                                    <app-article-details [article]="selectedArticle"></app-article-details>
                                </div>
                            </td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
        </div>
    </div>
</div>