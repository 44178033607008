import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from '../../services/session.service';
import { ArticleService } from 'src/app/modules/article/services/article.service';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { ISession, ISessionAddress, ISessionProduct, SessionAddressType } from '../../services/session.interface';
import { CartService } from 'src/app/services/cart/cart.service';
import { ICart } from 'src/app/services/cart/cart';
import { Article, ArticleType, GoodCategory, GoodCategoryTranslationMap, RelatedArticle, SelectableArticle } from 'src/app/modules/article/services/article.interface';
import { CartItem, ICartItem } from 'src/app/services/cart/cart-item';
import { environment } from 'src/environments/environment';
import { calculatePrice } from 'src/app/utils/calculate-price';
import { MessageService } from 'src/app/services/message/message.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentMethod } from 'src/app/utils/payment-methods';
import { BillingFormat } from 'src/app/utils/billing-formats';
import { SessionUtils } from '../session-create/utils/session-utils';

@Component({
  selector: 'app-session-web',
  templateUrl: './session-web.component.html',
  styleUrls: ['./session-web.component.less']
})
export class SessionWebComponent extends SessionUtils implements OnInit, OnDestroy {

  public cart: ICart;
  public cartId: string = "";
  public session: ISession | undefined;
  public deliveryAddress: ISessionAddress | undefined;
  public billingAddress: ISessionAddress | undefined;
  public articles: Article[] = [];
  public modalOpened: string;
  public form: FormGroup;
  public loading = false;

  public goodCategoryTranslationMap = GoodCategoryTranslationMap;

  public initialPaymentMethod: PaymentMethod | undefined = undefined;
  public initialBillingFormat: BillingFormat | undefined = undefined;

  constructor(
    private readonly sessionService: SessionService,
    private readonly articleService: ArticleService,
    private readonly route: ActivatedRoute,
    private readonly cartService: CartService,
    private readonly messageService: MessageService,
    private readonly formBuilder: FormBuilder,
  ) {
    super();
  }

  public activeTab: string = 'tab-content-meta';
  public toggleTab(tab: string) {
    this.activeTab = tab;
  }

  public async getSessionGoods() {

    const goods = this.cart.getCartElements().map((item) => {
      const totalPrice = item.calculatePrice();
      const unitVat = Number(((totalPrice / item.quantity) - item.price).toFixed(0));

      return {
        quantity: item.quantity,
        unitVat: unitVat,
        vat: item.vat,
        unitPrice: item.price,
        totalPrice: item.calculatePrice(),
        title: item.title,
        position: item.position,
        sku: item.sku
      } as ISessionProduct;
    });

    const totalPrice = this.cart.getCartTotal();
    const totalCount = goods.length;

    return {
      goods, totalPrice, totalCount
    }
  }

  public async save() {

    // Validate form
    if (this.form.invalid) {
      this.messageService.createMessage('A megadott adatok közül egy vagy több hibás! Nézze át újra!'); return;
    }

    const { goods, totalCount, totalPrice } = await this.getSessionGoods();

    const requestBody = {
      session: {
        id: this.session?.id,
        sessionType: this.session?.sessionType,
        sessionState: this.session?.sessionState,
        paymentMethod: this.session?.paymentMethod,
        deliveryMethod: this.session?.deliveryMethod,
        billingFormat: this.session?.billingFormat,
        billingMethod: this.session?.billingMethod,
        source: this.session?.source,
        comment: this.session?.comment,
        totalPrice: totalPrice,
        totalCount: totalCount,
      },
      vehicle: this.form.value.vehicle,
      address: [
        { ...this.form.value.deliveryAddress, phone: this.concatenatePhoneNumber(this.form.value.deliveryAddress.phonePrefix, this.form.value.deliveryAddress.phone) },
        { ...this.form.value.billingAddress, phone: this.concatenatePhoneNumber(this.form.value.billingAddress.phonePrefix, this.form.value.billingAddress.phone) },
      ],
      article: goods
    };

    // send save request
    this.sessionService.createSession(requestBody).subscribe({
      next: () => { this.messageService.createMessage('Módosítások mentése sikeres!', 'success') },
      error: (e) => { this.messageService.createMessage(e.message, 'error') }
    });
  }

  private initializeForm(response: ISession): FormGroup {

    const billingAddress = response.address.find((address: ISessionAddress) => address.type === SessionAddressType.BILLING) || {} as ISessionAddress;
    const deliveryAddress = response.address.find((address: ISessionAddress) => address.type === SessionAddressType.DELIVERY) || {} as ISessionAddress;

    const { prefix: billingAddressPhonePrefix, number: billingAddressPhoneNumber } = this.splitPhoneNumber(billingAddress.phone);
    const { prefix: deliveryAddressPhonePrefix, number: deliveryAddressPhoneNumber } = this.splitPhoneNumber(deliveryAddress.phone);

    this.initialPaymentMethod = response.paymentMethod as PaymentMethod;
    this.initialBillingFormat = response.billingFormat as BillingFormat;

    return this.formBuilder.group({

      id: [response.id],

      vehicle: this.formBuilder.group({
        plate: [response.vehicle.plate, Validators.required],
        milage: [response.vehicle.milage],
        manufacturedIn: [response.vehicle.manufacturedIn],
        brand: [response.vehicle.brand],
        model: [response.vehicle.model],
        year: [response.vehicle.year],
      }),

      deliveryAddress: this.formBuilder.group(
        {
          type: [SessionAddressType.DELIVERY, Validators.required],
          name: [deliveryAddress.name],
          email: [deliveryAddress.email],
          phonePrefix: [deliveryAddressPhonePrefix],
          phone: [deliveryAddressPhoneNumber],
          zipcode: [deliveryAddress.zipcode],
          city: [deliveryAddress.city],
          address: [deliveryAddress.address],
          taxnumber: [deliveryAddress.taxnumber],
        }
      ),

      billingAddress: this.formBuilder.group(
        {
          type: [SessionAddressType.BILLING, Validators.required],
          name: [billingAddress.name],
          email: [billingAddress.email],
          phonePrefix: [billingAddressPhonePrefix],
          phone: [billingAddressPhoneNumber],
          zipcode: [billingAddress.zipcode],
          city: [billingAddress.city],
          address: [billingAddress.address],
          taxnumber: [billingAddress.taxnumber],
        }
      ),

      sessionType: [response.sessionType, Validators.required],
      sessionState: [response.sessionState, Validators.required],

      article: [response.article],

      paymentMethod: [response.paymentMethod],
      billingFormat: [response.billingFormat],
      billingMethod: [response.billingMethod],
      readyToSymbol: [response.readyToSymbol],
      source: [response.source]

    });
  }

  private async getRouteParams(): Promise<Params> {
    return firstValueFrom(this.route.params);
  }

  private async fetchSession(id: number): Promise<ISession> {
    return firstValueFrom(this.sessionService.getSession(id));
  }

  public getDeliveryAddress(): ISessionAddress | undefined {
    return this.session?.address.find((address: ISessionAddress) => address.type === SessionAddressType.DELIVERY);
  }

  public getBillingAddress(): ISessionAddress | undefined {
    return this.session?.address.find((address: ISessionAddress) => address.type === SessionAddressType.BILLING);
  }

  ngOnDestroy(): void {
    if (this.cartId) { this.cartService.removeCart(this.cartId); }
  }

  async readAllGoods(): Promise<void> {
    this.articles = await lastValueFrom(this.articleService.getArticles());
  }

  public pendingCartItem: SelectableArticle;
  private createCartItem(item: SelectableArticle, quantity: number, position: string = ""): ICartItem {

    // reset pending cart item quantity
    this.pendingCartItem = {
      ...this.pendingCartItem,
      quantity: 1,
    }

    return new CartItem({
      id: item.id,
      title: item.title,
      price: item.price,
      vat: item.vat,
      quantity: quantity,
      discount: 0,
      position: position,
      sku: item.sku,
    });
  }

  public appendToCart(item: SelectableArticle) {

    const cartItem = this.createCartItem(item, item.quantity || 1, item.position);
    this.cart.appendItem(cartItem).updateTotalValue();

    // find and add related goods to the cart
    const article = this.articles.find((good: Article) => good.sku === item.sku);

    if (article && article.relatedArticles && Array.isArray(article.relatedArticles) && article.relatedArticles.length > 0) {

      article.relatedArticles.forEach((related: RelatedArticle) => {
        const relatedGood = this.articles.find((articleItem: Article) => articleItem.sku === related.relatedGoodSku) as SelectableArticle;
        if (relatedGood) {
          this.cart.appendItem(this.createCartItem(relatedGood, 1)).updateTotalValue()
        }
      })
    }

    this.cartService.storeCart(this.cartId, this.cart);
    this.closeModalWindow();
  }

  public insertCartElement(item: SelectableArticle, manualInsert = false) {

    if (manualInsert && !item.composite && item.forcePositionSelection) {
      this.openModalWindow('position-selector');
      this.pendingCartItem = item;
      return;
    }

    this.appendToCart(item);
  }

  public isNonEditableGood(sku: string): boolean {
    return environment.nonEditableGoods.includes(sku);
  }

  private escapeKeyHandler = this.escapeHandler.bind(this);

  private escapeHandler(e: KeyboardEvent): void {
    if (e.key === "Escape") {
      this.closeModalWindow();
    }
  }

  public selectedGoodCategory: GoodCategory;
  public selectedGoodType: ArticleType;

  public closeModalWindow(): void {
    this.modalOpened = "";
    document.removeEventListener("keyup", this.escapeKeyHandler);
  }

  public openModalWindow(id: string): void {
    this.modalOpened = id;
    document.addEventListener("keyup", this.escapeKeyHandler);
  }

  public cartItemToModify: CartItem | undefined;
  public cartItemToModifyForm: { quantity: number; price: number; } = { price: 0, quantity: 1 };
  public modifyCartItem(cartItem: CartItem) {

    this.cartItemToModify = cartItem;

    const { quantity, price, vat, discount } = cartItem;

    this.cartItemToModifyForm.price = calculatePrice(price, vat, discount, 1);
    this.cartItemToModifyForm.quantity = quantity;

    this.openModalWindow('cart-item-editor');
  }

  public saveCartItemModifications(): void {

    if (!this.cartItemToModify) {
      return;
    }

    if (this.cartItemToModify?.quantity === 0) {
      this.messageService.createMessage('Érvénytelen mennyiség!', 'error');
      return;
    }

    const { id, title, position, discount, sku } = this.cartItemToModify;

    this.cart.removeItem(this.cartItemToModify);

    const newCartItem = new CartItem({
      id: id,
      title: title,
      vat: 0, // vat has to be zero as price defined as gross
      price: this.cartItemToModifyForm.price,
      discount: discount,
      quantity: this.cartItemToModifyForm.quantity,
      position: position,
      sku: sku
    });

    this.cart.appendItem(newCartItem);

    this.cart.updateTotalValue();
    this.cartItemToModify = undefined;
    this.closeModalWindow();
  }

  public removeCartElement(item: ICartItem) {

    const { transitiveRelatedGoods } = environment;

    // delete related elements from cart
    const article = this.articles.find((article: Article) => article.sku === item.sku);

    // delete transitive related goods
    if (article && article.sku in transitiveRelatedGoods) {
      const transitiveGoodSku: string = transitiveRelatedGoods[article.sku];
      const transitive = this.articles.find((articleItem: Article) => articleItem.sku === transitiveGoodSku) as SelectableArticle;
      if (transitive) {
        this.cart.removeItem(this.createCartItem(transitive, 1)).updateTotalValue();
      }
    }

    // delete related goods from cart
    if (article && article.relatedArticles && Array.isArray(article.relatedArticles) && article.relatedArticles.length > 0) {

      article.relatedArticles.forEach((related: RelatedArticle) => {
        const relatedGood = this.articles.find((articleItem: Article) => articleItem.sku === related.relatedGoodSku) as SelectableArticle;
        if (relatedGood) {
          this.cart.removeItem(this.createCartItem(relatedGood, 1)).updateTotalValue();
        }
      })
    }

    this.cart.removeItem(item).updateTotalValue();
  }

  public async ngOnInit(): Promise<void> {

    const { id } = await this.getRouteParams();

    if (id) {

      this.loading = true;

      const sessionResponse = await this.fetchSession(id);

      this.form = this.initializeForm(sessionResponse);

      await this.readAllGoods();

      this.session = sessionResponse;
      this.deliveryAddress = this.getDeliveryAddress();
      this.billingAddress = this.getBillingAddress();
      this.cartId = `SESSION_CART_${this.session.id}`;

      // create a new cart for the session
      this.cartService.removeCart(this.cartId);
      this.cart = this.cartService.createCart(this.cartId);

      this.session.article.forEach((sessionProductItem: ISessionProduct) => {
        const article = this.articles.find((article: Article) => article.sku === sessionProductItem.sku);

        if (article) {

          // use price and vat of stored session good item
          article.price = sessionProductItem.unitPrice;
          article.vat = sessionProductItem.vat;

          const cartItem = this.createCartItem(article, sessionProductItem.quantity, sessionProductItem.position);
          this.cart.appendItem(cartItem).updateTotalValue();
        }
      });

      this.loading = false;
    }
  }
}
