<table *ngIf="session">
    <thead>
        <tr>
            <th>Termék / szolgáltatás</th>
            <th>Mennyiség</th>
            <th>Egységár</th>
            <th>Végösszeg</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let product of session.article">
            <td>{{product.title}}</td>
            <td>{{product.quantity | number}}</td>
            <td>{{product.unitPrice | number}}</td>
            <td>{{product.totalPrice | number}}</td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <th colspan="3"></th>
            <th>{{session.totalPrice | number}}</th>
        </tr>
    </tfoot>
</table>