import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SessionService } from '../../../services/session.service';
import { ISession, ISessionAddress, SessionAddressType } from '../../../services/session.interface';
import { MessageService } from 'src/app/services/message/message.service';
import { SessionUtils } from '../utils/session-utils';

@Component({
  selector: 'app-session-filter',
  templateUrl: './session-filter.component.html',
  styleUrls: ['./session-filter.component.less']
})
export class SessionFilterComponent extends SessionUtils implements OnInit {

  public list: { raw: ISession[], filtered: ISession[], previous: ISession[] } = { raw: [], filtered: [], previous: [] };

  @ViewChild("filterInput") filterInput:
    | ElementRef<HTMLInputElement>
    | undefined;

  @Output() toggleValue: EventEmitter<{ session: ISession, repeat: boolean }> = new EventEmitter();
  @Input() plate: string = "";

  constructor(private readonly sessionService: SessionService, private readonly messageService: MessageService) { super(); }

  private cmpStr(in1: string, in2: string): boolean {
    if (in1 && in2 && (in1.trim().toLowerCase().includes(in2.trim().toLowerCase()))) { return true }
    return false;
  }

  public getBillingAddress(session: ISession) {
    return session.address.find((address: ISessionAddress) => address.type === SessionAddressType.BILLING) || {} as ISessionAddress;
  }

  ngOnInit(): void {

    this.sessionService.getSessions().subscribe({
      next: (sessions: ISession[]) => {

        // get last 3 sessions related to this license plate
        if (this.plate) {
          this.list.previous = sessions.filter((session: ISession) => session.vehicle.plate === this.plate).slice(0, 3);
          if (this.list.previous.length >= 1) {
            this.list.filtered = this.list.previous;
          }
        }

        this.list.raw = sessions;
        this.setInputFieldFocus(this.filterInput);

      },
      error: (e) => console.error(e)
    })
  }

  backToList() {
    this.selectedSession = undefined;
  }

  public selectedSession: ISession | undefined = undefined;
  public showDetails(item: ISession) { this.selectedSession = item; }

  public filter(keyword: string) {

    if (keyword && keyword.length < 3) { return; }

    if (!keyword) {
      this.list.filtered = this.list.previous;
      return;
    }

    this.list.filtered = this.list.raw.filter((item: ISession) => {
      const billingAddress : ISessionAddress = this.getBillingAddress(item);
      return (
        this.cmpStr(billingAddress.phone, keyword) ||
        this.cmpStr(billingAddress.name, keyword) ||
        this.cmpStr(billingAddress.address, keyword) ||
        this.cmpStr(item.vehicle.plate, keyword) ||
        this.cmpStr(billingAddress.city, keyword) ||
        this.cmpStr(billingAddress.taxnumber, keyword)
      )
    });
  }

  public select(session: ISession, repeat: boolean = false) {
    this.toggleValue.emit({ session, repeat });
  }
}
