export enum GoodCategory {
  'HOTEL' = 'HOTEL',          // GUMIHOTEL
  'INSTALL' = 'INSTALL',      // GUMISZERVÍZ
  'PROMOTION' = 'PROMOTION',  // PROMÓCIÓ
  'REPAIR' = 'REPAIR',        // AUTÓSZERVÍZ
  'RIM' = 'RIM',              // FELNI
  'TIRE' = 'TIRE',            // ABRONCS
  'TUBE' = 'TUBE',            // TÖMLŐ
  'WASH' = 'WASH',             // MÓSÓ
  'PRODUCT' = 'PRODUCT',
  'SERVICE' = 'SERVICE'
}

export enum GoodSubCategory {
  'BODYWORK' = 'BODYWORK',
  'ELECTRONIC' = 'ELECTRONIC',
  'GENERAL' = 'GENERAL',
  'DIAGNOSTIC' = 'DIAGNOSTIC',
  'SUSPENSION' = 'SUSPENSION',
  'DRIVECHAIN' = 'DRIVECHAIN',
  'PAINT' = 'PAINT',
  'ENGINE' = 'ENGINE',
  'OFFICIAL' = 'OFFICIAL',
  'BREAK' = 'BREAK',
  'RESCUE' = 'RESCUE',
  'AIRCONDITION' = 'AIRCONDITION',
  'DRINK' = 'DRINK',
  'DISCOUNT' = 'DISCOUNT',
  'HAND' = 'HAND',
  'MACHINE' = 'MACHINE',
  'CENTERING' = 'CENTERING',
  'AGRO' = 'AGRO',
  'REPAIR-RIM' = 'REPAIR-RIM',
  'FLEET' = 'FLEET',
  'TRUCK' = 'TRUCK',
  'REPAIR-TIRE' = 'REPAIR-TIRE',
  'INSTALL-ALU' = 'INSTALL-ALU',
  'INSTALL-TIRE' = 'INSTALL-TIRE',
  'INSTALL-STEEL' = 'INSTALL-STEEL',
  'PACKAGE' = 'PACKAGE',
  'HOTEL' = 'HOTEL'
}


export const GoodCategoryTranslationMap: Record<GoodCategory, string> = {
  [GoodCategory.REPAIR]: 'Autószervíz',
  [GoodCategory.HOTEL]: 'Gumihotel',
  [GoodCategory.INSTALL]: ' Gumiszervíz',
  [GoodCategory.WASH]: 'Mosó',
  [GoodCategory.PROMOTION]: 'Promóciós termékek',
  [GoodCategory.RIM]: 'Felni',
  [GoodCategory.TIRE]: 'Abroncs',
  [GoodCategory.TUBE]: 'Tömlő',
  [GoodCategory.PRODUCT]: 'Termék',
  [GoodCategory.SERVICE]: 'Szolgáltatás',
}

export const GoodSubCategoryTranslationMap: Record<GoodSubCategory, string> = {
  [GoodSubCategory.BODYWORK]: 'Karosszéria',
  [GoodSubCategory.ELECTRONIC]: 'Elektromos',
  [GoodSubCategory.GENERAL]: 'Általános',
  [GoodSubCategory.DIAGNOSTIC]: 'Diagnosztika',
  [GoodSubCategory.SUSPENSION]: 'Futómű',
  [GoodSubCategory.DRIVECHAIN]: 'Hajtáslánc',
  [GoodSubCategory.PAINT]: 'Fényezés',
  [GoodSubCategory.ENGINE]: 'Motor',
  [GoodSubCategory.OFFICIAL]: 'Hatósági',
  [GoodSubCategory.BREAK]: 'Fék',
  [GoodSubCategory.RESCUE]: 'Mentés',
  [GoodSubCategory.AIRCONDITION]: 'Klíma',
  [GoodSubCategory.DRINK]: 'Ital',
  [GoodSubCategory.DISCOUNT]: 'Promóciós kedvezmény',
  [GoodSubCategory.HAND]: 'Kézi mosás',
  [GoodSubCategory.MACHINE]: 'Gépi mosás',
  [GoodSubCategory.CENTERING]: 'Centírozás',
  [GoodSubCategory.AGRO]: 'Agro',
  [GoodSubCategory['REPAIR-RIM']]: 'Felni javítás',
  [GoodSubCategory.FLEET]: 'Flotta',
  [GoodSubCategory.TRUCK]: 'Teher',
  [GoodSubCategory['REPAIR-TIRE']]: 'Gumijavítás',
  [GoodSubCategory['INSTALL-ALU']]: 'Alu szerelés',
  [GoodSubCategory['INSTALL-TIRE']]: 'Gumi szerelés',
  [GoodSubCategory['INSTALL-STEEL']]: 'Acél szerelés',
  [GoodSubCategory.PACKAGE]: 'Szolgáltatási csomag',
  [GoodSubCategory.HOTEL]: 'Tárolás'
}

export interface RelatedArticle {
  id: number;
  sourceSku: string;
  relatedGoodSku: string;
  createdAt: string;
  updatedAt: string;
}

export interface SelectableArticle extends Article {
  quantity?: number;
  position?: string;
  forcePositionSelection?: boolean;
}

export enum ArticleType {
  "SERVICE" = "SERVICE",
  "PRODUCT" = "PRODUCT",
}

export enum ArticleKeys {
  'id' = 'id',
  'sku' = 'sku',
  'title' = 'title',
  'price' = 'price',
  'vat' = 'vat',
  'type' = 'type',
  'composite' = 'composite',
  'category' = 'category',
  'subCategory' = 'subCategory',
  'categoryTree' = 'categoryTree',
  'stock' = 'stock',
  'barcode' = 'barcode',
  'shop' = 'shop',
  'enabled' = 'enabled',
  'slug' = 'slug'
}

export interface Article {
  id: number;
  sku: string;
  title: string;
  price: number;
  vat: number;
  type: ArticleType;
  composite: boolean;
  category: string;
  subCategory: string;
  categoryTree: string;
  stock: number;
  barcode: string;
  shop: string;
  enabled: boolean;
  slug: string;
  attributes: ArticleAttribute;
  relatedArticles: Array<RelatedArticle>;
}

export enum ArticleAttributeKeys {
  'id' = 'id',
  'sku' = 'sku',
  'width' = 'width',
  'height' = 'height',
  'radius' = 'radius',
  'weight' = 'weight',
  'brand' = 'brand',
  'pattern' = 'pattern',
  'placement' = 'placement',
  'loadIndex' = 'loadIndex',
  'speedIndex' = 'speedIndex',
  'qualityRate' = 'qualityRate',
  'safetyRate' = 'safetyRate',
  'comfortRate' = 'comfortRate',
  'milageRate' = 'milageRate',
  'fuelEfficiency' = 'fuelEfficiency',
  'noiseLevelLabel' = 'noiseLevelLabel',
  'cohesionLabel' = 'cohesionLabel',
  'season' = 'season',
  'snowUsage' = 'snowUsage',
  'iceUsage' = 'iceUsage',
  'class' = 'class',
  'eprelLabel' = 'eprelLabel',
  'customAttributes' = 'customAttributes',
  'airSealing' = 'airSealing',
  'generation' = 'generation',
  'structure' = 'structure',
  'tread' = 'tread',
  'mixture' = 'mixture',
  'usage' = 'usage',
  'terrainRatio' = 'terrainRatio',
  'fimCertification' = 'fimCertification',
  'sideWall' = 'sideWall',
  'weightIndex' = 'weightIndex',
  'origin' = 'origin',
  'category' = 'category',
  'patternRatio' = 'patternRatio',
  'central' = 'central',
  'et' = 'et',
  'series' = 'series',
  'color' = 'color',
  'design' = 'design',
  'type' = 'type',
  'valve' = 'valve',
  'tireSizes' = 'tireSizes',
  'hd' = 'hd',
  'preparationTimeInMinutes' = 'preparationTimeInMinutes'
}

export interface ArticleAttribute {
  id: number;
  sku: string;
  width: string;
  height: string;
  radius: string;
  weight: string;
  brand: string;
  pattern: string;
  placement: string;
  loadIndex: string;
  speedIndex: string;
  qualityRate: string;
  safetyRate: string;
  comfortRate: string;
  milageRate: string;
  fuelEfficiency: string;
  noiseLevelLabel: string;
  cohesionLabel: string;
  season: string;
  snowUsage: string;
  iceUsage: string;
  class: string;
  eprelLabel: string;
  customAttributes: string;
  airSealing: string;
  generation: string;
  structure: string;
  tread: string;
  mixture: string;
  usage: string;
  terrainRatio: string;
  fimCertification: string;
  sideWall: string;
  weightIndex: string;
  origin: string;
  category: string;
  patternRatio: string;
  hd: string;
  central: string;
  et: string;
  series: string;
  color: string;
  design: string;
  type: string;
  valve: string;
  tireSizes: string;
  preparationTimeInMinutes: number;
}

export const ArticleI18N: Record<string, string> = {
  [ArticleKeys.id]: "Azonosító",
  [ArticleKeys.sku]: "Cikkszám",
  [ArticleKeys.title]: "Megnevezés",
  [ArticleKeys.price]: "Nettó eladási ár",
  [ArticleKeys.vat]: "Áfakulcs",
  [ArticleKeys.type]: "Típus",
  [ArticleKeys.composite]: "Kompozit",
  [ArticleKeys.category]: "Kategória",
  [ArticleKeys.subCategory]: "Alkategória",
  [ArticleKeys.categoryTree]: "Kategória fa",
  [ArticleKeys.stock]: "Aktuális készlet",
  [ArticleKeys.barcode]: "Vonalkód",
  [ArticleKeys.shop]: "Megjelenhet",
  [ArticleKeys.enabled]: "Aktív",
  [ArticleKeys.slug]: "Slug",
}

export const ArticleAttributeI18N: Record<string, string> = {
  [ArticleAttributeKeys.id]: "Azonosító",
  [ArticleAttributeKeys.sku]: "Cikkszám",
  [ArticleAttributeKeys.width]: "Szélesség",
  [ArticleAttributeKeys.height]: "Magasság",
  [ArticleAttributeKeys.radius]: "Átmérő",
  [ArticleAttributeKeys.weight]: "Súly",
  [ArticleAttributeKeys.brand]: "Márka",
  [ArticleAttributeKeys.pattern]: "Mintázat",
  [ArticleAttributeKeys.placement]: "Elhelyezés",
  [ArticleAttributeKeys.loadIndex]: "Terhelhetőségi index",
  [ArticleAttributeKeys.speedIndex]: "Sebesség index",
  [ArticleAttributeKeys.qualityRate]: "Minőségi értékelés",
  [ArticleAttributeKeys.safetyRate]: "Biztonsági értékelés",
  [ArticleAttributeKeys.comfortRate]: "Komfort értékelés",
  [ArticleAttributeKeys.milageRate]: "Kilóméter értékelés",
  [ArticleAttributeKeys.fuelEfficiency]: "Üzemanyag hatékonyság",
  [ArticleAttributeKeys.noiseLevelLabel]: "Zajszint",
  [ArticleAttributeKeys.cohesionLabel]: "Tapadás",
  [ArticleAttributeKeys.season]: "Idény",
  [ArticleAttributeKeys.snowUsage]: "Havas használatra",
  [ArticleAttributeKeys.iceUsage]: "Jeges használatra",
  [ArticleAttributeKeys.class]: "Osztály besorolás",
  [ArticleAttributeKeys.eprelLabel]: "EPREL címke",
  [ArticleAttributeKeys.customAttributes]: "Egyéni attribútumok",
  [ArticleAttributeKeys.airSealing]: "Légzárás",
  [ArticleAttributeKeys.generation]: "Generáció",
  [ArticleAttributeKeys.structure]: "Szerkezet",
  [ArticleAttributeKeys.tread]: "Futófelület",
  [ArticleAttributeKeys.mixture]: "Keverék",
  [ArticleAttributeKeys.usage]: "Felhasználás",
  [ArticleAttributeKeys.terrainRatio]: "Terep arány",
  [ArticleAttributeKeys.fimCertification]: "FIM hitelesítés",
  [ArticleAttributeKeys.sideWall]: "Oldalfal",
  [ArticleAttributeKeys.weightIndex]: "Súly index",
  [ArticleAttributeKeys.origin]: "Származási ország",
  [ArticleAttributeKeys.category]: "Kategória",
  [ArticleAttributeKeys.hd]: "Harley Davidson márkajelölés",
  [ArticleAttributeKeys.patternRatio]: "Minta arány",
  [ArticleAttributeKeys.central]: "Középfurat",
  [ArticleAttributeKeys.et]: "ET szám",
  [ArticleAttributeKeys.series]: "Osztókör",
  [ArticleAttributeKeys.color]: "Szín",
  [ArticleAttributeKeys.design]: "Kivitel",
  [ArticleAttributeKeys.type]: "Típus",
  [ArticleAttributeKeys.valve]: "Szelep",
  [ArticleAttributeKeys.tireSizes]: "Külső gumi méretek",
  [ArticleAttributeKeys.preparationTimeInMinutes]: "Elkészülési idő",
}