import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SessionListComponent } from "./components/session-list/session-list.component";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SessionCreateComponent } from "./components/session-create/session-create.component";
import { SessionDashboardComponent } from "./components/session-dashboard/session-dashboard.component";
import { SessionFlightboardComponent } from "./components/session-flightboard/session-flightboard.component";
import { SessionCountdownComponent } from './components/session-countdown/session-countdown.component';
import { GoodFilterComponent } from './components/session-create/good-filter/good-filter.component';
import { SessionFilterComponent } from './components/session-create/session-filter/session-filter.component';
import { NgxMaskDirective } from "ngx-mask";
import { SessionDetailsComponent } from './components/session-details/session-details.component';
import { PresenterComponent } from './components/session-list/presenter/presenter.component';
import { MeasurementsComponent } from './components/session-details/measurements/measurements.component';
import { PhotosComponent } from './components/session-details/photos/photos.component';
import { ProductsComponent } from './components/session-details/products/products.component';
import { BillingComponent } from './components/session-details/billing/billing.component';
import { PriceComponent } from './components/session-details/price/price.component';
import { SafePipe } from "./pipes/safe.pipe";
import { ServicePosititionTypePipe } from "./pipes/service-position-type.pipe";
import { SessionFlightboardTyrePositionComponent } from './components/session-flightboard/session-flightboard-tyre-position/session-flightboard-tyre-position.component';
import { BrowserModule } from "@angular/platform-browser";
import { UiModule } from "../ui/ui.module";
import { ProductListComponent } from './components/session-list/product-list/product-list.component';
import { PaymentMethodComponent } from './components/session-create/payment-method/payment-method.component';
import { VehicleAgeComponent } from './components/session-create/vehicle-age/vehicle-age.component';
import { CustomerHistoryComponent } from './components/session-create/customer-history/customer-history.component';
import { HotLinksComponent } from './components/session-create/hot-links/hot-links.component';
import { BillingMethodComponent } from './components/session-create/billing-method/billing-method.component';
import { WheelSelectorComponent } from './components/session-create/wheel-selector/wheel-selector.component';
import { SearchComponent } from './components/session-list/search/search.component';
import { SessionStatePipe } from "./pipes/session-state.pipe";
import { PhoneFormatPipe } from "./pipes/phone-format.pipe";
import { LicensePlateComponent } from './components/session-create/license-plate/license-plate.component';
import { WarehouseComponent } from './components/session-create/warehouse/warehouse.component';
import { SessionWebComponent } from './components/session-web/session-web.component';

@NgModule({
  declarations: [
    SessionListComponent,
    SessionCreateComponent,
    SessionDashboardComponent,
    SessionFlightboardComponent,
    SessionCountdownComponent,
    GoodFilterComponent,
    SessionFilterComponent,
    SessionDetailsComponent,
    PresenterComponent,
    MeasurementsComponent,
    PhotosComponent,
    ProductsComponent,
    BillingComponent,
    PriceComponent,
    SafePipe,
    SessionStatePipe,
    PhoneFormatPipe,
    ServicePosititionTypePipe,
    SessionFlightboardTyrePositionComponent,
    ProductListComponent,
    PaymentMethodComponent,
    VehicleAgeComponent,
    CustomerHistoryComponent,
    HotLinksComponent,
    BillingMethodComponent,
    WheelSelectorComponent,
    SearchComponent,
    LicensePlateComponent,
    WarehouseComponent,
    SessionWebComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskDirective,
    UiModule
  ],
})
export class SessionModule {}
