import { Component, Input, OnInit } from '@angular/core';
import { ISession, ISessionAddress, SessionAddressType } from '../../../services/session.interface';
import { PaymentMethod, PaymentMethodMap } from 'src/app/utils/payment-methods';

@Component({
  selector: 'app-session-details-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.less']
})
export class BillingComponent implements OnInit {
  @Input() session: ISession | undefined;

  public address : ISessionAddress | undefined;

  ngOnInit(): void {
    this.address = this.session?.address.find((address: ISessionAddress) => address.type === SessionAddressType.BILLING);
  }

  public getPaymentMethodName(method: string): string {
    return PaymentMethodMap[method as unknown as PaymentMethod];
  }
  public getBillingAddress(session: ISession) {
    return session.address.find((address: ISessionAddress) => address.type === SessionAddressType.BILLING) || {} as ISessionAddress;
  }
}
