import { common } from './common';
import { EnvironmentConfiguration } from './environment.interface';

export const environment: EnvironmentConfiguration = {
  ...common,
  production: true,
  services: {
    zipcode: 'https://zipcode.terrex.hu',
    sessionDetailsPage: 'https://details.autodrom.hu',
    inventory: {
      hostname: "https://inventory.terrex.hu",
      username: "terrex-inventory-user",
      password: "CaFTCNVcUXYn"
    },
  },
  api: {
    hostname: 'https://api-uat.autodrom.hu'
  },
};
