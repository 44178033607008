import { calculatePrice } from 'src/app/utils/calculate-price';

export type ICartItemFields = {
  id: number;
  title: string;
  price: number;
  vat: number;
  discount: number;
  quantity: number;
  position: string;
  sku:string;
};

export interface ICartItem extends ICartItemFields {
  calculatePrice: () => number;
}

export class CartItem implements ICartItem {
  public id: number = 0;
  public vat: number = 0;
  public price: number = 0;
  public discount: number = 0;
  public quantity: number = 0;
  public title: string = '';
  public position: string = "";
  public sku: string = "";

  public constructor(params: ICartItemFields) {
    this.id = params.id;
    this.vat = params.vat;
    this.price = params.price ? Number(params.price.toFixed(0)) : 0;
    this.discount = params.discount;
    this.quantity = params.quantity;
    this.title = params.title;
    this.position = params.position;
    this.sku = params.sku;
  }

  public calculatePrice(): number {
    return calculatePrice(this.price, this.vat, this.discount, this.quantity);
  }
}
