import { NgModule } from '@angular/core';
import { DropdownButtonComponent } from './components/dropdown-button/dropdown-button.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { DropdownNavigationButtonComponent } from './components/dropdown-navigation-button/dropdown-navigation-button.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';

@NgModule({
  declarations: [
    DropdownButtonComponent,
    DropdownNavigationButtonComponent,
    LoadingIndicatorComponent
  ],
  imports: [
    CommonModule,
    BrowserModule
  ],
  exports: [DropdownButtonComponent, DropdownNavigationButtonComponent, LoadingIndicatorComponent]
})
export class UiModule { }
