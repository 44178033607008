
export interface CommonEnvironmentConfig {
  appName: string;
  nonEditableGoods: string[];
  transitiveRelatedGoods: Record<string, string>
}

export const common: CommonEnvironmentConfig = {
  appName: "AutoDrom",
  nonEditableGoods: ['A_prmhcan'],
  transitiveRelatedGoods: {
    "A_HCAN250": "A_prmhcan"
  }
};
