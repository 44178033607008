import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuardService as AuthGuard } from "./modules/user/services/auth.guard.service";
import { getUserModuleRoutes } from "./modules/user/user.routes";
import { getSessionModuleRoutes } from "./modules/session/session.routes";
import { getArticleModuleRoutes } from "./modules/article/article.routes";
import { SessionDashboardComponent } from "./modules/session/components/session-dashboard/session-dashboard.component";
import { getContentModuleRoutes } from "./modules/content/content.routes";
import { DisplayConfigComponent } from "./components/display-config/display-config.component";

const routes: Routes = [
  ...getUserModuleRoutes(AuthGuard),
  ...getSessionModuleRoutes(AuthGuard),
  ...getArticleModuleRoutes(AuthGuard),
  ...getContentModuleRoutes(AuthGuard),
  {
    path: "config",
    component: DisplayConfigComponent,
    canActivate: [AuthGuard],
    data: { showNavigation: false }
  },
  {
    path: "",
    component: SessionDashboardComponent,
    canActivate: [AuthGuard],
    data: { showNavigation: true },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
