<ng-container *ngIf="isAuthenticated">

    <div class="navigation">
        <div class="container">
            <div class="row">
                <div class="column column-10"><img src="/assets/ADblack.svg" /></div>
                <div class="column column-90 navigation-elements">
                    <a [routerLink]="['session/list']"><i class="fa fa-cash-register"></i> Munkamenetek</a>
                    <a [routerLink]="['session/board']"><i class="fa fa-dice-six"></i> Flightboard</a>
                    <a>
                        <app-dropdown-navigation-button [title]="'Beállítások'" [icon]="'fa fa-cogs'">
                            <ul>
                                <li><a [routerLink]="['article/list']"><i class="fa fa-box"></i> Inventory</a></li>
                                <li><a [routerLink]="['content/list']"><i class="fa fa-file"></i>Tartalmak</a></li>
                                <li><a [routerLink]="['user/list']"><i class="fa fa-users"></i>Felhasználók</a></li>
                                <li><a [routerLink]="['config']"><i class="fa fa-display"></i>Kijelzők</a></li>
                            </ul>
                        </app-dropdown-navigation-button>
                    </a>
                    <a>
                        <app-dropdown-navigation-button [title]="'Profil'" [icon]="'fa fa-user'">
                            <ul>
                                <li><a [routerLink]="['user/profile']"><i class="fa fa-cogs"></i>Személyes beállítások</a></li>
                                <li><a [routerLink]="['user/logout']"><i class="fa fa-times"></i>Kijelentkezés</a></li>
                            </ul>
                        </app-dropdown-navigation-button>
                    </a>
                </div>
            </div>
        </div>
    </div>

</ng-container>