<div class="container">
    <div class="row">
        <div class="column">
            <h5>Alap adatok</h5>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <table class="table-small">
                <tbody>
                    <tr *ngFor="let key of articleKeys;">
                        <td width="200">
                            <b>{{articleKeysI18N[key]}}</b>
                        </td>
                        <td>
                            {{getArticleFieldValue(key)}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <h5>Attribútumok</h5>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <table class="table-small">
                <tr *ngFor="let key of attributeKeys">
                    <td width="200">
                        <b>{{attributeKeysI18n[key]}}</b>
                    </td>
                    <td>
                        {{getArticleAttributeFieldValue(key)}}
                    </td>
                </tr>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <h5>Kapcsolódó elemek</h5>
        </div>
    </div>
    <div class="row">
        <div class="column">
            <table class="table-small">
                <tbody>
                    <tr *ngFor="let relatedArticle of article.relatedArticles;">
                        <td width="200">
                            <b>Cikkszám</b>
                        </td>
                        <td>
                            {{relatedArticle.relatedGoodSku}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>