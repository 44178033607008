import { Routes } from "@angular/router";
import { ArticleListComponent } from "./components/article-list/article-list.component";

export const getArticleModuleRoutes = (ActivationGuard: any): Routes => {
  return [
    {
      path: "article/list",
      component: ArticleListComponent,
      canActivate: [ActivationGuard],
      data: { showNavigation: true, componentTitle: "Termékek - szolgáltatások listája" },
    }
  ];
};
