import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Article } from "./article.interface";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ArticleService {

  private basicInventoryServiceCallHeaders = {
    "Authorization": "Basic " + btoa(`${environment.services.inventory.username}:${environment.services.inventory.password}`)
  }

  constructor(private readonly http: HttpClient) { }

  public getArticles(): Observable<Article[]> {
    const endpoint = `${environment.services.inventory.hostname}/data/autodrom-articles.json`;
    return this.http.get<Article[]>(endpoint, {
      headers: {
        ...this.basicInventoryServiceCallHeaders
      }
    });
  }

  public searchArticle(term: string): Observable<Article[]> {
    return this.http.get<Article[]>(`${environment.services.inventory.hostname}/api/search-articles.php?term=${term}`, {
      headers: {
        ...this.basicInventoryServiceCallHeaders
      }
    });
  }

  public getArticle(sku: string): Observable<Article> {
    return this.http.get<Article>(`${environment.services.inventory.hostname}/api/get-article.php?sku=${sku}`, {
      headers: {
        ...this.basicInventoryServiceCallHeaders
      }
    });
  }
}
