import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Article, ArticleType, GoodCategory, GoodSubCategory, GoodSubCategoryTranslationMap, SelectableArticle } from 'src/app/modules/article/services/article.interface';
import { SessionUtils } from '../utils/session-utils';
import { debounceTime, fromEvent } from 'rxjs';

enum StockFilterOptions {
  ALL = 'ALL',
  STOCK_ONLY = 'STOCK_ONLY'
}

@Component({
  selector: 'app-good-filter',
  templateUrl: './good-filter.component.html',
  styleUrls: ['./good-filter.component.less']
})
export class GoodFilterComponent extends SessionUtils implements OnInit, AfterViewInit {

  @Input() goodType: ArticleType;
  @Input() goodCategory: GoodCategory;
  @Input() goods: Article[] = [];

  public stockFilterOptions = StockFilterOptions;

  public stockFilter: StockFilterOptions = StockFilterOptions.ALL;
  public textFilter: string = "";
  public subCategoryFilter: string = "";

  public subCategoryFilterMap: Record<string, string[]> = {
    [GoodCategory.REPAIR]: [
      GoodSubCategory.BODYWORK,
      GoodSubCategory.ELECTRONIC,
      GoodSubCategory.GENERAL,
      GoodSubCategory.SUSPENSION,
      GoodSubCategory.PAINT,
      GoodSubCategory.DRIVECHAIN,
      GoodSubCategory.ENGINE,
      GoodSubCategory.OFFICIAL,
      GoodSubCategory.BREAK,
      GoodSubCategory.DIAGNOSTIC,
      GoodSubCategory.RESCUE,
      GoodSubCategory.AIRCONDITION
    ],
    [GoodCategory.INSTALL]: [
      GoodSubCategory.CENTERING,
      GoodSubCategory.AGRO,
      GoodSubCategory.PACKAGE,
      GoodSubCategory['REPAIR-RIM'],
      GoodSubCategory.FLEET,
      GoodSubCategory.TRUCK,
      GoodSubCategory['REPAIR-TIRE'],
      GoodSubCategory['INSTALL-ALU'],
      GoodSubCategory['INSTALL-TIRE'],
      GoodSubCategory['INSTALL-STEEL'],
      GoodSubCategory.HOTEL
    ],
    [GoodCategory.WASH]: [
      GoodSubCategory.HAND,
      GoodSubCategory.MACHINE
    ],
  };

  @ViewChild("filterInput", { static: true }) filterInput: | ElementRef<HTMLInputElement>;

  public list: { raw: SelectableArticle[], filtered: SelectableArticle[], inited: SelectableArticle[] } = { raw: [], filtered: [], inited: [] }

  @Output() toggleValue: EventEmitter<SelectableArticle> = new EventEmitter();

  public loading = false;

  constructor() { super(); }

  ngAfterViewInit(): void {
    fromEvent(this.filterInput.nativeElement, 'keyup').pipe(debounceTime(500)).subscribe({
      next: () => {
        this.textFilter = this.filterInput.nativeElement.value;
        if (this.textFilter.length > 0 && this.textFilter.length >= 3) { this.filter(); }
        if (this.textFilter.length == 0) { this.filter(); }
      }
    })
  }

  public getSubCategoryI18n(subCategory: string): string {
    return GoodSubCategoryTranslationMap[subCategory as unknown as GoodSubCategory];
  }

  ngOnInit(): void {
    this.list.raw = this.goods;
    this.initList();
    this.setInputFieldFocus(this.filterInput);
  }

  public setStockFilter(event: Event) {
    const target = event.target as HTMLSelectElement;
    this.stockFilter = target.value as StockFilterOptions;
    this.filter();
  }

  public setSubCategoryFilter(filter: string) {
    this.subCategoryFilter = this.subCategoryFilter === filter ? "" : filter;
    this.filter()
  }

  public initList() {
    const list = this.list.inited.length > 0 ? this.list.inited : this.prefilter(this.goods, this.goodType, this.goodCategory);
    this.list.inited = list;
    this.list.filtered = list;
  }

  public prefilter(goods: Article[], type: ArticleType, category?: GoodCategory) {
    const typeFilter = (item: Article, value?: ArticleType) => type && item.type && value ? item.type === value : true;
    const categoryFilter = (item: Article, value?: GoodCategory) => category && item.category && value ? item.category === value : true;
    return goods.filter((item: Article) => (typeFilter(item, type) && categoryFilter(item, category))).slice(0, 100);
  }

  public filter() {

    this.loading = true;

    const keyword = this.textFilter?.toLowerCase();

    const titleFilter = (item: Article, keyword: string) => keyword ? item.title?.toLowerCase().includes(keyword) : true;
    const skuFilter = (item: Article, keyword: string) => keyword ? item.sku?.toLowerCase().includes(keyword) : true;
    const subCategoryFilter = (item: Article) => this.subCategoryFilter ? item.subCategory?.toLowerCase() === this.subCategoryFilter?.toLowerCase() : true;
    const stockFilter = (item: Article) => this.stockFilter === StockFilterOptions.ALL ? true : item.stock > 0;

    this.list.filtered = this.list.raw.filter((item: Article) => subCategoryFilter(item) && (titleFilter(item, keyword) || skuFilter(item, keyword))).slice(0, 100);

    // apply stock filtering
    if (this.stockFilter !== StockFilterOptions.ALL) {
      this.list.filtered = this.list.filtered.filter((item: Article) => stockFilter(item));
    }

    this.loading = false;

  }

  public select(good: SelectableArticle) {
    this.toggleValue.emit({ ...good, quantity: 1, forcePositionSelection: this.goodCategory === GoodCategory.INSTALL });
  }
}
