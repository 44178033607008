import { Component, Input } from "@angular/core";
import { Article, ArticleAttributeI18N, ArticleI18N, ArticleKeys, ArticleAttribute, ArticleAttributeKeys } from "../../services/article.interface";

@Component({
  selector: "app-article-details",
  templateUrl: "./article-details.component.html",
  styleUrls: ["./article-details.component.less"],
})
export class ArticleDetailsComponent {

  public attributeKeys: string[] = Object.keys(ArticleAttributeKeys);
  public attributeKeysI18n = ArticleAttributeI18N;

  public articleKeys: string[] = Object.keys(ArticleKeys);
  public articleKeysI18N = ArticleI18N;

  @Input() article: Article;

  constructor(
  ) { }

  public getArticleFieldValue(key: string) {
    return this.article ? this.article[key as ArticleKeys] : null;
  }
  public getArticleAttributeFieldValue(key: string) {
    return this.article && this.article.attributes ? this.article.attributes[key as keyof ArticleAttribute] : null;
  }
}
