<div class="wrapper" *ngIf="session">
    <div class="sidebar">
        <div class="logo"><img src="./assets/ADblack.svg" alt="AD Logo" /></div>
        <div class="details">
            <table class="details-table">
                <tr>
                    <td>Dátum</td>
                    <td>{{session.createdAt}}</td>
                </tr>
                <tr>
                    <td>Számla</td>
                    <td>
                        <a class="btn" *ngIf="session.invoiceLink" target="_blank" href="{{session.invoiceLink}}">Számla
                            letöltése</a>
                        <span *ngIf="!session.invoiceLink"> Még nem elérhető</span>

                    </td>
                </tr>
                <tr>
                    <td>Mérési eredmények</td>
                    <td>
                        <a class="btn" target="_blank" *ngIf="session.hunterLink" href="{{session.hunterLink}}">Mérési
                            eredmények letöltése</a>
                        <span *ngIf="!session.hunterLink">Még nem elérhető</span>
                    </td>
                </tr>
            </table>
            <hr>
            <table class="details-table">
                <tr>
                    <td>Név</td>
                    <td>{{address?.name}}</td>
                </tr>
                <tr>
                    <td>Adószám</td>
                    <td>{{address?.taxnumber}}</td>
                </tr>
                <tr>
                    <td>Telefon</td>
                    <td>{{address?.phone?.replace("###","")}}</td>
                </tr>
                <tr>
                    <td>Számlázási cím</td>
                    <td>{{address?.zipcode}} {{address?.city}} {{address?.address}}</td>
                </tr>
                <tr>
                    <td>Forgalmi rendszám</td>
                    <td>{{session.vehicle.plate}}</td>
                </tr>
                <tr>
                    <td>Fizetési mód</td>
                    <td>{{getPaymentMethodName(session.paymentMethod)}}</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="contentbar">
        <div class="items-wrapper">
            <table class="items">
                <caption>Szolgáltatások</caption>
                <thead>
                    <tr>
                        <th scope="col" class="blue rounded-left">Szolgáltatás</th>
                        <th scope="col" class="gray">Nettó</th>
                        <th scope="col" class="gray">Áfa</th>
                        <th scope="col" class="gray">Menny.</th>
                        <th scope="col" class="gray rounded-right">Bruttó</th>
                    </tr>
                </thead>
                <tbody>

                    <tr *ngFor="let item of session.article">
                        <td scope="row" data-label="Szolgáltatás">{{item.title}}</td>
                        <td data-label="Nettó">{{item.unitPrice | number}} Ft</td>
                        <td data-label="Áfa">{{item.unitVat | number}} Ft</td>
                        <td data-label="Menny.">{{item.quantity | number}} db</td>
                        <td data-label="Bruttó">{{item.totalPrice | number}} Ft</td>
                    </tr>

                </tbody>
            </table>
        </div>
        <div class="total">
            <span class="total-label">Összesen</span>
            <span class="total-price">{{session.totalPrice | number}}</span>
        </div>
    </div>
</div>