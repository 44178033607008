<app-loading-indicator [loading]="!session && loading"></app-loading-indicator>

<div class="container" *ngIf="session">

    <form [formGroup]="form" autocomplete="off">

        <div class="tab-pane">
            <div class="row">
                <div class="column tab-pane-column tab-pane-left">
                    <ul class="tab-toolbar">
                        <li><a [ngClass]="{'active':activeTab === 'tab-content-meta'}"
                                (click)="toggleTab('tab-content-meta')">Meta adatok</a></li>
                        <li><a [ngClass]="{'active':activeTab === 'tab-content-address'}"
                                (click)="toggleTab('tab-content-address')">Címek</a></li>
                        <li><a [ngClass]="{'active':activeTab === 'tab-content-products'}"
                                (click)="toggleTab('tab-content-products')">Termékek</a></li>

                        <li><a [ngClass]="{'active':activeTab === 'tab-content-vehicle'}"
                                (click)="toggleTab('tab-content-vehicle')">Jármű adatok</a></li>
                        <li>
                            <button (click)="save()"><i class="fa fa-save"></i> Mentés</button>
                        </li>
                    </ul>

                </div>
                <div class="column tab-pane-column">
                    <div class="tab-content-pane">
                        <div class="tab-content" [ngClass]="{'active':activeTab === 'tab-content-meta'}"
                            id="tab-content-meta">
                            <h3>Rendelés meta adatai</h3>
                            <table class="table-small">
                                <tbody>
                                    <tr>
                                        <td width="200" class="header-cell">ID</td>
                                        <td>{{session.id}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header-cell">Létrehozva</td>
                                        <td>{{session.createdAt}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header-cell">Utolsó módosítás</td>
                                        <td>{{session.updatedAt}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header-cell">Webáruház</td>
                                        <td>{{session.source}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header-cell">Állapot</td>
                                        <td>{{session.sessionState}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header-cell">Végösszeg</td>
                                        <td><b>{{session.totalPrice | number}}</b></td>
                                    </tr>
                                    <tr>
                                        <td class="header-cell">Fizetési mód</td>
                                        <td>{{session.paymentMethod}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header-cell">Fizetési státusz</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td class="header-cell">Szállítási mód</td>
                                        <td>{{session.deliveryMethod}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header-cell">Termékek száma</td>
                                        <td>{{session.totalCount}}</td>
                                    </tr>
                                    <tr>
                                        <td class="header-cell">Megrendelő megjegyzése</td>
                                        <td>{{session.comment}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="tab-content" [ngClass]="{'active':activeTab === 'tab-content-address'}"
                            id="tab-content-address">
                            <div>
                                <div>
                                    <h3>Szállítási cím</h3>
                                    <table class="table-small" formGroupName="deliveryAddress">
                                        <tbody>
                                            <tr>
                                                <td width="200" class="header-cell">Név</td>
                                                <td>
                                                    <input type="text" formControlName="name" id="delivery-address-name"
                                                        name="delivery-address-name" autocomplete="address-level4">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="header-cell">Szállítási cím</td>
                                                <td>
                                                    <div class="grid-flow">
                                                        <label for="">Irányítószám</label>
                                                        <label for="">Város</label>
                                                        <label for="">Utca, házszám</label>
                                                        <input type="text" formControlName="zipcode"
                                                            id="delivery-address-zipcode"
                                                            name="delivery-address-zipcode"
                                                            autocomplete="address-level4">
                                                        <input type="text" formControlName="city"
                                                            id="delivery-address-city" name="delivery-address-city"
                                                            autocomplete="address-level4">
                                                        <input type="text" formControlName="address"
                                                            id="delivery-address-address"
                                                            name="delivery-address-address"
                                                            autocomplete="address-level4">
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="header-cell">E-mail</td>
                                                <td>
                                                    <input type="text" formControlName="email"
                                                        id="delivery-address-email" name="delivery-address-email"
                                                        autocomplete="address-level4">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="header-cell">Telefon</td>
                                                <td>
                                                    <div class="grid-flow">
                                                        <select name="delivery-phone-prefix" id="delivery-phone-prefix"
                                                            formControlName="phonePrefix" style="height:auto">
                                                            <option value="+3620">+36 20</option>
                                                            <option value="+3630">+36 30</option>
                                                            <option value="+3650">+36 50</option>
                                                            <option value="+3670">+36 70</option>
                                                        </select>
                                                        <input type="text" formControlName="phone"
                                                            id="delivery-address-phone" name="delivery-address-phone"
                                                            autocomplete="address-level4">
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <h3>Számlázási cím</h3>
                                    <table class="table-small" formGroupName="billingAddress">
                                        <tbody>
                                            <tr>
                                                <td width="200" class="header-cell">Számlázási név</td>
                                                <td>
                                                    <input type="text" formControlName="name" id="delivery-address-name"
                                                        name="billing-address-name" autocomplete="address-level4">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="header-cell">Számlázási cím</td>
                                                <td>
                                                    <div class="grid-flow">
                                                        <label for="">Irányítószám</label>
                                                        <label for="">Város</label>
                                                        <label for="">Utca, házszám</label>
                                                        <input type="text" formControlName="zipcode"
                                                            id="billing-address-zipcode" name="billing-address-zipcode"
                                                            autocomplete="address-level4">
                                                        <input type="text" formControlName="city"
                                                            id="billing-address-city" name="billing-address-city"
                                                            autocomplete="address-level4">
                                                        <input type="text" formControlName="address"
                                                            id="billing-address-address" name="billing-address-address"
                                                            autocomplete="address-level4">
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="header-cell">E-mail</td>
                                                <td>
                                                    <input type="text" formControlName="email"
                                                        id="billing-address-email" name="billing-address-email"
                                                        autocomplete="address-level4">
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="header-cell">Telefon</td>
                                                <td>
                                                    <div class="grid-flow">
                                                        <select name="billing-phone-prefix" id="billing-phone-prefix"
                                                            formControlName="phonePrefix" style="height:auto">
                                                            <option value="+3620">+36 20</option>
                                                            <option value="+3630">+36 30</option>
                                                            <option value="+3650">+36 50</option>
                                                            <option value="+3670">+36 70</option>
                                                        </select>
                                                        <input type="text" formControlName="phone"
                                                            id="delivery-address-phone" name="billing-address-phone"
                                                            autocomplete="address-level4">
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="header-cell">Adószám</td>
                                                <td>
                                                    <input type="text" formControlName="taxnumber"
                                                        id="billing-address-taxnumber" name="billing-address-taxnumber"
                                                        autocomplete="address-level4">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                        <div class="tab-content" [ngClass]="{'active':activeTab === 'tab-content-vehicle'}"
                            id="tab-content-vehicle">
                            <h3>Jármű adatok</h3>
                            <table class="table-small" formGroupName="vehicle">
                                <tr>
                                    <td class="header-cell" width="200">Forgalmi rendszám</td>
                                    <td><input type="text" formControlName="plate" id="vehicle-plate"
                                            name="vehicle-plate" autocomplete="address-level4"></td>
                                </tr>
                                <tr>
                                    <td class="header-cell">Futásteljesítmény</td>
                                    <td><input type="text" formControlName="milage" id="vehicle-milage"
                                            name="vehicle-milage" autocomplete="address-level4"></td>
                                </tr>
                                <tr>
                                    <td class="header-cell">Márka</td>
                                    <td><input type="text" formControlName="brand" id="vehicle-brand"
                                            name="vehicle-brand" autocomplete="address-level4"></td>
                                </tr>
                                <tr>
                                    <td class="header-cell">Modell</td>
                                    <td><input type="text" formControlName="model" id="vehicle-model"
                                            name="vehicle-model" autocomplete="address-level4"></td>
                                </tr>
                                <tr>
                                    <td class="header-cell">Gyártás éve</td>
                                    <td><input type="text" formControlName="manufacturedIn" id="vehicle-manufacturedIn"
                                            name="vehicle-manufacturedIn" autocomplete="address-level4"></td>
                                </tr>
                                <tr>
                                    <td class="header-cell">Évjárat</td>
                                    <td><input type="text" formControlName="year" id="vehicle-year" name="vehicle-year"
                                            autocomplete="address-level4"></td>
                                </tr>
                            </table>
                        </div>
                        <div class="tab-content" [ngClass]="{'active':activeTab === 'tab-content-products'}"
                            id="tab-content-products">
                            <h3>Termékek</h3>
                            <table class="table-small">
                                <thead class="cart-items-toolbar">
                                    <tr>
                                        <th colspan="10">
                                            <a (click)="this.openModalWindow('service-selector');"><i
                                                    class="fa fa-plus"></i>
                                                Termék hozzadása</a>
                                        </th>
                                    </tr>
                                </thead>
                                <thead>
                                    <tr>
                                        <th>SKU</th>
                                        <th>Megnevezés</th>
                                        <th>Db</th>
                                        <th>Ár</th>
                                        <th>Művelet</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="cart && cart.getCartElements().length === 0">
                                        <td colspan="6">A kosár üres...</td>
                                    </tr>
                                    <tr class="cart-element-row" *ngFor="let cartItem of cart.getCartElements()">
                                        <td>{{cartItem.sku}}</td>
                                        <td>{{cartItem.title}}</td>
                                        <td>{{cartItem.quantity}}</td>
                                        <td class="price-text"><b>{{cartItem.calculatePrice() |
                                                number}}
                                                HUF</b></td>
                                        <td>
                                            <div style="display:flex;gap:10px;">
                                                <a *ngIf="!isNonEditableGood(cartItem.sku)" class="remove-cart-item-btn"
                                                    (click)="removeCartElement(cartItem)"><i
                                                        class="fa fa-times"></i></a>
                                                <a *ngIf="!isNonEditableGood(cartItem.sku)" class="edit-cart-item-btn"
                                                    (click)="modifyCartItem(cartItem)"><i class="fa fa-edit"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th colspan="3">Összesen</th>
                                        <th colspan="3">{{cart.getCartTotal() | number}} HUF</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </form>

</div>


<div class="modal position-selector" *ngIf="modalOpened=='cart-item-editor' && cartItemToModify">
    <div class="modal-content modal-content-narrow">
        <div class="modal-header">
            <h5>Tétel módosítása</h5>
            <span (click)="closeModalWindow();" class="close">&times;</span>
        </div>
        <div class="modal-body modal-auto-height">
            <label for="cart-item-quantity">Mennyiség</label>
            <input type="number" name="cart-item-quantity" [(ngModel)]="cartItemToModifyForm.quantity">
            <label for="cart-item-quantity">Ár (bruttó)</label>
            <input type="number" name="cart-item-price" [(ngModel)]="cartItemToModifyForm.price">
            <button class="btn btn-primary" (click)="saveCartItemModifications()"><i class="fa fa-save"></i>
                Mentés</button>
        </div>
    </div>
</div>

<div class="modal service-selector" *ngIf="modalOpened=='service-selector'">
    <div class="modal-content">
        <div class="modal-header">
            <h5>{{goodCategoryTranslationMap[selectedGoodCategory]}}</h5>
            <span (click)="closeModalWindow();" class="close">&times;</span>
        </div>
        <div class="modal-body">
            <app-good-filter [goods]="articles" [goodType]="selectedGoodType" [goodCategory]="selectedGoodCategory"
                (toggleValue)="insertCartElement($event, true)"></app-good-filter>
        </div>
    </div>
</div>