<div *ngIf="session">

    <h1 class="text-center">Termékek</h1>

    <div class="data-wrapper">
        <div class="data-wrapper-row" *ngFor="let item of session.article">
            <div>
                <div class="data-field data-field-value">{{item.title}}</div>
            </div>

            <div class="data-wrapper-inner-row">
                <div class="data-field data-field-value">{{item.quantity}}&nbsp;x&nbsp;</div>
                <div class="data-field data-field-value">{{item.unitPrice}}&nbsp;=&nbsp;</div>
                <div class="data-field data-field-value">{{item.totalPrice}}</div>
            </div>

        </div>
    </div>

</div>