import { ElementRef } from "@angular/core";
import { Article } from "src/app/modules/article/services/article.interface";
import { calculatePrice } from "src/app/utils/calculate-price";

export class SessionUtils {

    /**
     * Calculate full price of good
     */
    public formatPrice = (item: Article, quantity: number = 1): number => {
        return calculatePrice(item.price, item.vat, 0, quantity);
    }

    /**
     * Set focus on input field
     */
    public setInputFieldFocus(input: ElementRef<HTMLInputElement> | undefined): void {
        setTimeout(() => {
            input?.nativeElement.focus();
        }, 100);
    }

    public concatenatePhoneNumber(prefix: string, number: string) {
        return `${prefix}###${number}`;
    }

    public splitPhoneNumber(phone: string): { prefix: string | undefined, number: string | undefined } {
        const [prefix, number] = phone.split('###');
        return { prefix, number };
    }
}