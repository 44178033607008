import { Component, EventEmitter, Output } from '@angular/core';
import { ArticleType, GoodCategory } from 'src/app/modules/article/services/article.interface';

enum ProductSKU {
  'moseco' = 'A_moseco',
  'mosnano' = 'A_mosnano',
  'mospro' = 'A_mospro',
  'SZOLG451' = 'A_SZOLG451',
  'SZOLG45' = 'A_SZOLG45',
  'SZOLG351' = 'A_SZOLG351',
  'SZOLG46' = 'A_SZOLG46',
  'packeco' = 'A_packeco',
  'packextr' = 'A_packextr',
  'packperf' = 'A_packperf',
  'packsuv' = 'A_packsuv',
  'HCAN250' = 'A_HCAN250',
  'XCAN250' = 'A_XCAN250',
  'ICCAN250' = 'A_ICCAN250'
}

@Component({
  selector: 'app-hot-links',
  templateUrl: './hot-links.component.html',
  styleUrls: ['./hot-links.component.less']
})
export class HotLinksComponent {

  public availableGoodCategories = GoodCategory;
  public availableGoodTypes = ArticleType;

  public ProductElements: Record<ProductSKU, string> = {
    [ProductSKU.moseco]: 'Eco',
    [ProductSKU.mosnano]: 'Nano',
    [ProductSKU.mospro]: 'Protect',
    [ProductSKU.SZOLG451]: 'Futómű állítás',
    [ProductSKU.SZOLG45]: 'Futómű állítás első',
    [ProductSKU.SZOLG351]: 'Futómű állítás hátsó',
    [ProductSKU.SZOLG46]: 'Futómű diagnosztika',
    [ProductSKU.packeco]: 'Eco',
    [ProductSKU.packextr]: 'Extra',
    [ProductSKU.packperf]: 'Performance',
    [ProductSKU.packsuv]: 'SUV',
    [ProductSKU.HCAN250]: 'Hell 250ml CAN',
    [ProductSKU.XCAN250]: 'XIXO 250ml CAN',
    [ProductSKU.ICCAN250]: 'ICE COFFE 250ml CAN'
  }

  public productList: Record<GoodCategory, ProductSKU[]> = {
    [GoodCategory.WASH]: [ProductSKU.moseco, ProductSKU.mospro, ProductSKU.mosnano ],
    [GoodCategory.REPAIR]: [ProductSKU.SZOLG451, ProductSKU.SZOLG45, ProductSKU.SZOLG351, ProductSKU.SZOLG46],
    [GoodCategory.HOTEL]: [],
    [GoodCategory.INSTALL]: [ProductSKU.packeco, ProductSKU.packextr, ProductSKU.packsuv, ProductSKU.packperf],
    [GoodCategory.PROMOTION]: [ProductSKU.HCAN250, ProductSKU.XCAN250, ProductSKU.ICCAN250],
    [GoodCategory.RIM]: [],
    [GoodCategory.TIRE]: [],
    [GoodCategory.TUBE]: [],
    [GoodCategory.PRODUCT]: [],
    [GoodCategory.SERVICE]: [],
  };

  @Output() toggleValue: EventEmitter<{ category: GoodCategory, type: ArticleType }> = new EventEmitter();
  @Output() productSelected: EventEmitter<string> = new EventEmitter();

  public publishSelectedProduct(sku: string): void {
    this.productSelected.emit(sku);
  }

  public publish(type: ArticleType, category: GoodCategory): void {
    this.toggleValue.emit({ category, type });
  }

}
