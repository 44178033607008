// Defaults
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// Interceptors
import { AuthInterceptor } from "./interceptors/auth.interceptor";

// Components
import { AppComponent } from "./app.component";
import { NavigationComponent } from "./components/navigation/navigation.component";

// Modules
import { AppRoutingModule } from "./app-routing.module";
import { UserModule } from "./modules/user/user.module";
import { SessionModule } from "./modules/session/session.module";
import { AppVersionComponent } from "./components/app-version/app-version.component";
import { ArticleModule } from "./modules/article/article.module";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { ContentModule } from "./modules/content/content.module";
import { DisplayConfigComponent } from './components/display-config/display-config.component';
import { CommonModule } from "@angular/common";
import { UiModule } from "./modules/ui/ui.module";

@NgModule({
  declarations: [AppComponent, NavigationComponent, AppVersionComponent, BreadcrumbComponent, DisplayConfigComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    UserModule,
    SessionModule,
    ArticleModule,
    ContentModule,
    UiModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
