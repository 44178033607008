<div>

    <div class="filter-toolbar">
        <a [routerLink]="['/session/create']" class="button">Új munkamenet</a>
        <app-session-search (toggleValue)="filter($event)"></app-session-search>
        <select [(ngModel)]="currentListType" style="width:200px;" (change)="changeList($event)">
            <option value="active">Aktív munkamenetek</option>
            <option value="scheduled">Ütemezett munkamenetek</option>
            <option value="web">Webes rendelések</option>
            <option value="history">Korábbi munkamenetek</option>
        </select>
    </div>
</div>


<div *ngIf="list.raw.length > 0">
    <table>
        <thead>
            <tr>
                <th><i class="fa fa-edit"></i></th>
                <td><i class="fa fa-bank"></i></td>
                <th>ID</th>
                <th>Rendszám</th>
                <th>Név</th>
                <th>Cím</th>
                <th>Telefon</th>
                <th>Adószám</th>
                <th>Létrehozva</th>
                <th>Utolsó módosítás</th>
                <th class="text-center">Állapot</th>
                <th class="text-center">Idő</th>

            </tr>
        </thead>
        <tbody *ngIf="list.filtered.length > 0">
            <tr *ngFor="let session of list.filtered">
                <td width="40">
                    <app-dropdown-button [title]="'...'">
                        <ul>
                            <li>
                                <a *ngIf="session.sessionType !== 'WEB'" [routerLink]="['/session/edit/',session.id]"><i
                                        class="fa fa-file"></i>Szerkesztés</a>

                                <a *ngIf="session.sessionType == 'WEB'" [routerLink]="['/session/web/',session.id]"><i
                                        class="fa fa-file"></i>Szerkesztés</a>
                            </li>
                            <li *ngIf="session.sessionType !== 'WEB'"><a
                                    (click)="openModalWindow('presenter', session.id)"><i
                                        class="fa fa-eye"></i>Bemutatás</a></li>
                            <li *ngIf="session.sessionType !== 'WEB'"><a (click)="sendTextMessage(session)"><i
                                        class="fa fa-message"></i>SMS küldése</a></li>
                            <li><a (click)="deleteSession(session.id)"><i class="fa fa-times"></i>Törlés</a></li>
                            <li *ngIf="session.sessionType !== 'WEB'"><a (click)="setSessionAsBillable(session.id)"
                                    *ngIf="session.billingMethod === billingMethods.BILL && session.billingFormat === billingFormats.PAPER "><i
                                        class="fa fa-file-invoice"></i>Beküldés számlázásra</a></li>
                            <li><a (click)="closeSession(session.id)"
                                    *ngIf="session.paymentMethod === paymentMethods.BANK_TRANSFER "><i
                                        class="fa fa-check"></i>Átutalással rendezve, lezárható</a></li>
                        </ul>
                    </app-dropdown-button>
                </td>
                <td width="24">
                    <i *ngIf="session.paymentMethod === paymentMethods.BANK_TRANSFER" class="fa fa-bank"></i>
                </td>
                <td width="100">{{session.id}}</td>
                <td width="200"><b>{{session.vehicle.plate}}</b></td>
                <td>{{session.billingAddress?.name}}</td>
                <td>
                    <div *ngIf="session.sessionType != 'WEB'">
                        {{session.billingAddress?.zipcode}} {{session.billingAddress?.city}}
                        {{session.billingAddress?.address}}
                    </div>

                    <div *ngIf="session.sessionType == 'WEB'">
                        <div *ngIf="session.billingAddress">
                            <span style="width:100px;display: inline-block;">Számlázási cím : </span>
                            {{session.billingAddress.zipcode}} {{session.billingAddress.city}}
                            {{session.billingAddress.address}}
                        </div>
                        <div *ngIf="session.deliveryAddress">
                            <span style="width:100px; display: inline-block;">Szállítási cím : </span>
                            {{session.deliveryAddress.zipcode}} {{session.deliveryAddress.city}}
                            {{session.deliveryAddress.address}}
                        </div>
                    </div>

                </td>
                <td>{{session.billingAddress?.phone | phoneFormat}}</td>
                <td>{{session.billingAddress?.taxnumber}}</td>
                <td>{{session.createdAt}}</td>
                <td>{{session.updatedAt}}</td>
                <td class="text-center">{{session.sessionState | sessionState}}</td>
                <td width="100" style="text-align: center;">
                    <span
                        *ngIf="session.sessionState === 'ACTIVE' && session.sessionType !== 'WEB'"><app-session-countdown
                            [session]="session"></app-session-countdown></span>
                </td>

            </tr>
        </tbody>
        <tbody *ngIf="!list.raw.length">
            <tr>
                <td colspan="5">Nincs megjeleníthető adat...</td>
            </tr>
        </tbody>
    </table>
</div>

<div class="modal" *ngIf="modalOpened=='presenter'">
    <div class="modal-content modal-content-wide">
        <div class="modal-header">
            <h3>Bemutatás</h3>
            <span (click)="closeModalWindow();" class="close">&times;</span>
        </div>
        <div class="modal-body">
            <app-presenter [sessionId]="selectedSessionId"></app-presenter>
        </div>
    </div>
</div>